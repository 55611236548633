.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0) !important;
  color: darkgoldenrod !important;
}

.p-component-overlay-enter {
  background-color: rgba(0, 0, 0, .9) !important;
}

.p-galleria-item-next-icon {
  width: 90% !important;
  height: 40px !important;
  color: white !important;
}

.p-galleria-item-prev-icon {
  width: 90% !important;
  height: 40px !important;
  color: white !important;
}

.p-galleria-close-icon {
  width: 90% !important;
  height: 30px !important;
  margin-top: 20px !important;
  margin-right: 20px !important;
  color: white !important;
}